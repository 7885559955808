// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-galerija-js": () => import("./../../../src/pages/galerija.js" /* webpackChunkName: "component---src-pages-galerija-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-nastim-js": () => import("./../../../src/pages/nastim.js" /* webpackChunkName: "component---src-pages-nastim-js" */),
  "component---src-pages-onama-js": () => import("./../../../src/pages/onama.js" /* webpackChunkName: "component---src-pages-onama-js" */),
  "component---src-pages-staze-kosmaja-js": () => import("./../../../src/pages/staze_kosmaja.js" /* webpackChunkName: "component---src-pages-staze-kosmaja-js" */),
  "component---src-pages-viaferrata-js": () => import("./../../../src/pages/viaferrata.js" /* webpackChunkName: "component---src-pages-viaferrata-js" */),
  "component---src-templates-blog-list-template-js": () => import("./../../../src/templates/blog-list-template.js" /* webpackChunkName: "component---src-templates-blog-list-template-js" */),
  "component---src-templates-blog-template-js": () => import("./../../../src/templates/blog-template.js" /* webpackChunkName: "component---src-templates-blog-template-js" */),
  "component---src-templates-najave-list-template-js": () => import("./../../../src/templates/najave-list-template.js" /* webpackChunkName: "component---src-templates-najave-list-template-js" */),
  "component---src-templates-tour-template-js": () => import("./../../../src/templates/tour-template.js" /* webpackChunkName: "component---src-templates-tour-template-js" */)
}

